import type { Options } from '../AdobeTagManager/AdobeTagManager';
import type AdobeTagManager from '../AdobeTagManager/AdobeTagManager';

type MetricsConfigOptions = Options & { Interface: typeof AdobeTagManager };

const metricsConfig = ({
  debug,
  digitalData,
  Interface,
  seedFile,
  server,
}: MetricsConfigOptions) => ({
  enabled: true,
  vendors: [
    {
      name: 'Adobe Tag Manager',
      api: new Interface({
        debug,
        digitalData,
        seedFile,
        server,
      }),
    },
  ],
  debug,
});

export default metricsConfig;

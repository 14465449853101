import { allEnv } from '@dx-ui/framework-env';

export function getQueryProviderProps() {
  const env = allEnv();
  return {
    appId: env.DX_AUTH_API_CUSTOMER_APP_ID,
    incompleteLoggingEnabled: env.RUMCS_CAPTURE_CUSTOM_ERRORS === 'enabled',
    gqlEndpoints: {
      browser: env.DX_GRAPHQL_CLIENT_URI,
      server: process.env.DX_GRAPHQL_SERVER_URI as string,
    },
    authEndpoints: {
      browser: env.DX_AUTH_API_CLIENT_URI,
      server: process.env.DX_AUTH_API_SERVER_URI as string,
    },
  };
}

/* @ts strict */
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { sendReward, sendRewardAsync } from '@dx-ui/framework-conductrics';
import { digitalData } from '../../metrics/constants';
import type { FormDefaultValues, FormDataValues } from '@dx-ui/osc-shop-form';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { GOALS } from '../../conductricsConstants';
import { useRouter } from 'next/router';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { logError } from '@dx-ui/framework-logger';

export type TSearchState = (FormDefaultValues | FormDataValues) & { displayCurrency?: string };

type SearchStateProvider = {
  children: React.ReactNode;
  initialSearchState?: TSearchState | null;
};

const SearchContext = createContext<{
  searchState: TSearchState | null;
  initializeSearchState: (args: TSearchState) => void;
  onSearchChange: (args: TSearchState, options?: { skipStateUpdate?: boolean }) => void;
}>({
  searchState: null,
  initializeSearchState() {
    return undefined;
  },
  onSearchChange() {
    return undefined;
  },
});

export function useSearchContext() {
  return useContext(SearchContext);
}

const SESSION_KEY = 'property_search_state';

export function SearchProvider({ children, initialSearchState = null }: SearchStateProvider) {
  const hasInitializedSearchStateRef = useRef(false);
  const router = useRouter();
  const { guestInfo, isLoading: isAuthLoading } = useAuth();

  if (isBrowser && initialSearchState) {
    window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(initialSearchState));
  }

  const sessionState = isBrowser ? window.sessionStorage.getItem(SESSION_KEY) : null;
  const initialState: TSearchState | null = sessionState ? JSON.parse(sessionState) : null;

  if (initialState?.dates?.arrivalDate)
    initialState.dates.arrivalDate = new Date(initialState.dates.arrivalDate);
  if (initialState?.dates?.departureDate)
    initialState.dates.departureDate = new Date(initialState.dates.departureDate);

  const [searchState, setSearchState] = useState<TSearchState | null>(initialState);

  const syncUrl = useCallback(
    async (nextState: TSearchState) => {
      const [url, search] = router.asPath.split('?');
      const searchParams = new URLSearchParams(search);
      searchParams.delete('hotelSlug');
      if (searchParams.has('smbRate')) {
        searchParams.set('smbRate', String(Boolean(nextState?.specialRates?.smbRate)));
        await router.replace({ pathname: url, query: searchParams.toString() }, undefined, {
          shallow: true,
        });
      }
    },
    [router]
  );

  const onSearchChange = useCallback(
    async (results: TSearchState, { skipStateUpdate }: { skipStateUpdate?: boolean } = {}) => {
      if (results && !isEqual(results, searchState)) {
        if (isBrowser) {
          window.sessionStorage.setItem(SESSION_KEY, JSON.stringify(results));
        }
        if (results?.dates?.datesFlex) {
          sendReward(GOALS.FLEXIBLE_DATES_CTA);
        }
        const previousRoomCount = searchState?.rooms?.length || 0;
        const newRoomCount = results?.rooms?.length || 0;
        if (previousRoomCount !== newRoomCount && newRoomCount > 1) {
          sendReward('edit-property-search-to-multi-room');
        }
        if (results.dates?.departureDate && !skipStateUpdate) {
          const prevDepartureDate = searchState?.dates?.departureDate;
          if (results.dates.departureDate !== prevDepartureDate) {
            sendReward(GOALS.EDIT_CALENDAR_DATES);
          }
          await syncUrl(results);
          setSearchState(results);
        }
        await sendRewardAsync(GOALS.EDIT_PROPERTY_SEARCH_WIDGET);
        if (
          !(digitalData.conductrics_traits as string[]).includes(
            'edit-property-widget:edited-widget'
          )
        ) {
          (digitalData.conductrics_traits as string[]).push('edit-property-widget:edited-widget');
        }
        if (digitalData.page?.pageInfo?.pageType === 'Rooms') {
          await sendRewardAsync(GOALS.EDIT_PROPERTY_ROOMS_PAGE_SEARCH_WIDGET);
        }
      }
    },
    [searchState, syncUrl]
  );

  const initializeSearchState = useCallback((formState: TSearchState) => {
    if (!hasInitializedSearchStateRef.current) {
      hasInitializedSearchStateRef.current = true;
      setSearchState(formState);
    }
  }, []);

  useEffect(() => {
    async function syncState() {
      const isSMBMember = guestInfo?.hhonors?.isSMBMember;
      const searchParams = new URLSearchParams(window.location.search);
      const hasSmbRateParam = searchParams.has('smbRate');
      const smbRateValue = searchParams.get('smbRate') || '';
      const shopWithSmbRate = Boolean(/1|true/.test(smbRateValue));
      const searchStateSMBRate = searchState?.specialRates?.smbRate ?? false;

      if (!isAuthLoading && hasSmbRateParam) {
        if (shopWithSmbRate !== searchStateSMBRate || (shopWithSmbRate && !isSMBMember)) {
          const newState = {
            ...searchState,
            specialRates: {
              ...searchState?.specialRates,
              smbRate: Boolean(isSMBMember && shopWithSmbRate),
            },
          };
          await syncUrl(newState);
          setSearchState(newState);
        }
      }
    }

    syncState().catch((error: string | Error | Record<string, unknown>) =>
      logError('SEARCH_PROVIDER', error, 'failed to run syncState()')
    );
  }, [guestInfo?.hhonors?.isSMBMember, isAuthLoading, searchState, syncUrl]);

  return (
    <SearchContext.Provider value={{ searchState, onSearchChange, initializeSearchState }}>
      {children}
    </SearchContext.Provider>
  );
}

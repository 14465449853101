import type { SSRConfig, UserConfig } from 'next-i18next';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { appWithTranslation as nextI18NextAppWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import i18nextHttpBackend from 'i18next-http-backend';
import type { OneLinkPageProps } from './util';

export function appWithTranslation<Props extends AppProps<SSRConfig & OneLinkPageProps>>(
  ...[WrappedComponent, rest]: Parameters<typeof nextI18NextAppWithTranslation<Props>>
) {
  const I18nFrameworkAppWithTranslation = (props: Props) => {
    const language = props.router.locale || 'en';
    const { _nextI18Next, appName, isOneLink, oneLinkConfig } = props.pageProps || {};

    if (isBrowser && appName && _nextI18Next?.userConfig && oneLinkConfig) {
      const appPath = oneLinkConfig?.appPaths?.[appName];
      if (appPath) {
        if (isOneLink) {
          const browserLocalePrefix = !!language && language !== 'en' ? `/${language}` : '';
          const browserLocalePath = `${browserLocalePrefix}/${appPath}/locales`;
          _nextI18Next.userConfig = {
            ..._nextI18Next.userConfig,
            use: [i18nextHttpBackend],
            backend: {
              loadPath: `${browserLocalePath}/en/{{ns}}.json`,
              addPath: `${browserLocalePath}/en/{{ns}}.missing.json`,
            },
          };
        } else {
          _nextI18Next.userConfig = {
            ..._nextI18Next.userConfig,
            use: [i18nextHttpBackend],
            backend: {
              loadPath: `/${appPath}/locales/{{lng}}/{{ns}}.json`,
              addPath: `/${appPath}/locales/{{lng}}/{{ns}}.missing.json`,
            },
          };
        }
      }
    }

    const userConfig =
      rest || _nextI18Next?.userConfig?.i18n
        ? { ...rest, ...(_nextI18Next?.userConfig as UserConfig) }
        : undefined;

    return nextI18NextAppWithTranslation(WrappedComponent, userConfig)(props);
  };

  return hoistNonReactStatics(I18nFrameworkAppWithTranslation, WrappedComponent);
}

import { useQueryClient } from '@tanstack/react-query';
import { PROPERTY_DATA_QUERY_KEY } from '../helpers/constants';

type PropertyData = {
  brandCode: string;
};

export function usePropertyData() {
  const client = useQueryClient();
  return client.getQueryData<PropertyData>(PROPERTY_DATA_QUERY_KEY);
}

import { createContext, useContext } from 'react';
import { PROPERTY_TEMPLATE } from '../helpers/constants';

import type { ReactNode } from 'react';
import type { TBaseConfig } from '../config/types';

type TGlobalConfigProviderProps = {
  children: ReactNode;
  globalConfig: TBaseConfig['globalConfig'] | undefined;
};

const GlobalConfigContext = createContext<TGlobalConfigProviderProps['globalConfig']>(undefined);

export const GlobalConfigProvider = ({ children, globalConfig }: TGlobalConfigProviderProps) => (
  <GlobalConfigContext.Provider value={globalConfig}>{children}</GlobalConfigContext.Provider>
);

export const useGlobalConfig = () => {
  const globalConfig = useContext(GlobalConfigContext);
  const isTailored = globalConfig?.template === PROPERTY_TEMPLATE.TAILORED;

  return {
    ...globalConfig,
    isTailored,
  };
};

import Head from 'next/head';
import { parseToRgb } from 'polished';
import { usePropertyData } from '../../hooks/use-property-data';
import { className, minifyInlineStyles } from '../../helpers/themes/customTheme';

import type { ThemeColors } from '../../helpers/themes/customTheme';

const toRgb = (hex: string) => {
  const { red: r, green: g, blue: b } = parseToRgb(hex.trim());

  return `${r},${g},${b}`;
};

const applyWhiteVariant = (hex: string, brandCode: string) =>
  parseInt(hex.replace('#', ''), 16) > 0xffffff / 2 && ['gu'].includes(brandCode);

type ThemeVariant = keyof Omit<ThemeColors, `${string}Contrast`>;

function commonThemeVariables(variant: ThemeVariant) {
  return `
    --color-bg: var(--theme-colors-${variant});
    --color-text: var(--theme-colors-${variant}-contrast);
    --color-text-heading: var(--theme-colors-${variant}-contrast);
    --color-bg-alt: var(--theme-colors-${variant}-contrast);
    --color-brand-alt: var(--theme-colors-${variant}-contrast);
    --color-border: var(--theme-colors-${variant}-contrast);
    --btn-bg-color-solid: var(--theme-colors-${variant}-contrast);
    --btn-bg-hover-color-solid: var(--theme-colors-${variant});
    --btn-text-color-solid: var(--theme-colors-${variant});
    --btn-text-hover-color-solid: var(--theme-colors-${variant}-contrast);
    --btn-border-color-solid: var(--theme-colors-${variant}-contrast);
    --btn-border-hover-color-solid: var(--theme-colors-${variant}-contrast);
    --btn-bg-color-outline: var(--theme-colors-${variant});
    --btn-bg-hover-color-outline: var(--theme-colors-${variant}-contrast);
    --btn-text-color-outline: var(--theme-colors-${variant}-contrast);
    --btn-text-hover-color-outline: var(--theme-colors-${variant});
    --btn-border-color-outline: var(--theme-colors-${variant}-contrast);
    --btn-border-hover-color-outline: var(--theme-colors-${variant}-contrast);
    --alert-normal-bg-color: var(--theme-colors-${variant});
    --alert-urgent-bg-color: var(--theme-colors-${variant});
    --alert-emergency-bg-color: var(--theme-colors-${variant});
    --alert-urgent-icon-color: var(--theme-colors-${variant}-contrast);
    --alert-emergency-icon-color: var(--theme-colors-${variant}-contrast);

    ${
      variant === 'tertiary'
        ? `
            --btn-bg-color-solid: var(--theme-colors-${variant}-contrast);
            --btn-bg-hover-color-solid: var(--theme-colors-${variant});
            --btn-text-color-solid: var(--theme-colors-${variant});
            --btn-text-hover-color-solid: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-text-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-text-hover-color: var(--theme-colors-${variant});
            --shop-form-btn-bg-color: var(--theme-colors-${variant});
            --shop-form-btn-bg-hover-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-border-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-border-hover-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-submit-text-color: var(--theme-colors-${variant});
            --shop-form-btn-submit-text-hover-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-submit-bg-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-submit-bg-hover-color: var(--theme-colors-${variant});
            --shop-form-btn-submit-border-color: var(--theme-colors-${variant}-contrast);
            --shop-form-btn-submit-border-hover-color: var(--theme-colors-${variant}-contrast);
          `
        : ''
    }
  `;
}

export function ThemeStyles(props: { theme: ThemeColors }) {
  const brandCode = usePropertyData()?.brandCode.toLowerCase() ?? '';

  return (
    <Head>
      <style>
        {minifyInlineStyles(`
          .theme-${brandCode} {
            --theme-colors-primary: ${toRgb(props.theme.primary)};
            --theme-colors-primary-contrast: ${toRgb(props.theme.primaryContrast)};
            --theme-colors-secondary: ${toRgb(props.theme.secondary)};
            --theme-colors-secondary-contrast: ${toRgb(props.theme.secondaryContrast)};
            --theme-colors-tertiary: ${toRgb(props.theme.tertiary)};
            --theme-colors-tertiary-contrast: ${toRgb(props.theme.tertiaryContrast)};
          }
          .theme-${brandCode} .${className.primary} {
            ${commonThemeVariables('primary')}

            color: ${props.theme.primaryContrast};
            background-color: ${props.theme.primary};
          }
          .theme-${brandCode} .${className.secondary} {
            ${commonThemeVariables('secondary')}

            color: ${props.theme.secondaryContrast};
            background-color: ${props.theme.secondary};
          }
          .theme-${brandCode} .${className.tertiary} {
            ${commonThemeVariables('tertiary')}

            color: ${props.theme.tertiaryContrast};
            background-color: ${props.theme.tertiary};
          }
          .theme-${brandCode} .${className.altPrimary} {
            --color-bg-alt: var(--theme-colors-primary);
            --color-text-alt: var(--theme-colors-primary-contrast);
          }
          .theme-${brandCode} .${className.altSecondary} {
            --color-bg-alt: var(--theme-colors-secondary);
            --color-text-alt: var(--theme-colors-secondary-contrast);
          }
          .theme-${brandCode} .${className.altTertiary} {
            --color-bg-alt: var(--theme-colors-tertiary);
            --color-text-alt: var(--theme-colors-tertiary-contrast);
          }
          .theme-${brandCode} .${className.altPrimary} .bg-bg-alt .btn-brand {
            ${commonThemeVariables('primary')}
          }
          .theme-${brandCode} .${className.altSecondary} .bg-bg-alt .btn-brand {
            ${commonThemeVariables('secondary')}
          }
          .theme-${brandCode} .${className.altTertiary} .bg-bg-alt .btn-brand {
            ${commonThemeVariables('tertiary')}
          }
          .theme-${brandCode} .${className.brandAltBrand} {
            --color-brand-alt: var(--color-brand);
          }
          .theme-${brandCode} .${className.brandPrimary} {
            --color-brand: var(--theme-colors-primary);
          }
          .theme-${brandCode} .${className.buttonPrimaryContrast} {
            --btn-text-color: var(--theme-colors-primary-contrast);
            --btn-text-hover-color: var(--theme-colors-primary-contrast);
          }
          .theme-${brandCode} .${className.colorPrimaryOverride} {
            --color-primary: var(--color-text);
            --color-primary-alt: var(--color-text);
          }
          .theme-${brandCode} .${className.headingBrand} {
            --color-text-heading: var(--color-brand);
          }
          .theme-${brandCode} .${className.textAltPrimaryContrast} {
            --color-text-alt: var(--theme-colors-primary-contrast);
          }
          ${
            applyWhiteVariant(props.theme.primaryContrast, brandCode)
              ? `
              .theme-${brandCode} .${className.primary} .header-logo {
                --header-logo-filter: contrast(2) grayscale(1) invert(1);
              }
            `
              : ''
          }
          ${
            applyWhiteVariant(props.theme.secondaryContrast, brandCode)
              ? `
              .theme-${brandCode} .${className.secondary} .header-logo {
                --header-logo-filter: contrast(2) grayscale(1) invert(1);
              }
            `
              : ''
          }
          ${
            applyWhiteVariant(props.theme.tertiaryContrast, brandCode)
              ? `
              .theme-${brandCode} .${className.tertiary} .header-logo {
                --header-logo-filter: contrast(2) grayscale(1) invert(1);
              }
            `
              : ''
          }
          .theme-${brandCode} .${className.navItemHoverPrimary} .nav-list-item-active,
          .theme-${brandCode} .${className.navItemHoverPrimary} .nav-list-item:focus,
          .theme-${brandCode} .${className.navItemHoverPrimary} .nav-list-item:focus-within,
          .theme-${brandCode} .${className.navItemHoverPrimary} .nav-list-item:hover {
            --color-text: var(--theme-colors-primary-contrast);
            --header-item-hover-color: var(--theme-colors-primary);
            --header-item-hover-border-color: var(--theme-colors-primary-contrast);
            --nav-main-link-text-color: var(--theme-colors-primary-contrast);
            color: ${props.theme.primaryContrast};
          }
          .theme-${brandCode} .${className.navItemHoverSecondary} .nav-list-item-active,
          .theme-${brandCode} .${className.navItemHoverSecondary} .nav-list-item:focus,
          .theme-${brandCode} .${className.navItemHoverSecondary} .nav-list-item:focus-within,
          .theme-${brandCode} .${className.navItemHoverSecondary} .nav-list-item:hover {
            --color-text: var(--theme-colors-secondary-contrast);
            --header-item-hover-color: var(--theme-colors-secondary);
            --header-item-hover-border-color: var(--theme-colors-secondary-contrast);
            --nav-main-link-text-color: var(--theme-colors-secondary-contrast);
            color: ${props.theme.secondaryContrast};
          }
          .theme-${brandCode} .${className.navItemHoverTertiary} .nav-list-item-active,
          .theme-${brandCode} .${className.navItemHoverTertiary} .nav-list-item:focus,
          .theme-${brandCode} .${className.navItemHoverTertiary} .nav-list-item:focus-within,
          .theme-${brandCode} .${className.navItemHoverTertiary} .nav-list-item:hover {
            --color-text: var(--theme-colors-tertiary-contrast);
            --header-item-hover-color: var(--theme-colors-tertiary);
            --header-item-hover-border-color: var(--theme-colors-tertiary-contrast);
            --nav-main-link-text-color: var(--theme-colors-tertiary-contrast);
            color: ${props.theme.tertiaryContrast};
          }
          .theme-${brandCode} .${className.navItemHoverBorder} {
            --nav-item-border-width: 8px;
          }
          .theme-${brandCode} .${className.navLowerPrimary} .nav-bottom-link-area {
            --header-base-color: var(--theme-colors-primary);
            --nav-bottom-area-base-color: var(--theme-colors-primary);
            --nav-main-link-text-color: var(--theme-colors-primary-contrast);
          }
          .theme-${brandCode} .${className.navLowerSecondary} .nav-bottom-link-area {
            --header-base-color: var(--theme-colors-secondary);
            --nav-bottom-area-base-color: var(--theme-colors-secondary);
            --nav-main-link-text-color: var(--theme-colors-secondary-contrast);
          }
          .theme-${brandCode} .${className.navLowerTertiary} .nav-bottom-link-area {
            --header-base-color: var(--theme-colors-tertiary);
            --nav-bottom-area-base-color: var(--theme-colors-tertiary);
            --nav-main-link-text-color: var(--theme-colors-tertiary-contrast);
          }
      `)}
      </style>
    </Head>
  );
}

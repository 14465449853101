import type { IncomingMessage, OutgoingMessage } from 'http';
import type { Extract } from './auth-client';
import { AuthClient } from './auth-client';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export interface GqlEndpoints {
  browser: string;
  server: string;
}

export interface AuthEndpoints {
  browser: string;
  server: string;
}

export type MakeAuthClientArgs = {
  appId: string;
  appName: string;
  authEndpoints: AuthEndpoints;
  gqlEndpoints: GqlEndpoints;
  request?: IncomingMessage;
  response?: OutgoingMessage;
  restore?: Extract;
  ssrMode?: boolean;
};

export function makeAuthClient({
  appName,
  request,
  restore,
  ssrMode,
  response,
  authEndpoints,
  gqlEndpoints,
  appId,
}: MakeAuthClientArgs) {
  return new AuthClient({
    appName,
    request,
    restore,
    authEndpoint: isBrowser ? authEndpoints?.browser : authEndpoints?.server,
    appId,
    gqlEndpoint: isBrowser ? gqlEndpoints?.browser : gqlEndpoints?.server,
    ssr: ssrMode,
    response,
  });
}

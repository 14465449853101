import type { FC } from 'react';
import { createContext } from 'react';
import type { TMetricsProviderContext, TMetricsProviderProps } from './metrics-provider.types';

const MetricsProviderContext = createContext<TMetricsProviderContext>(
  undefined as unknown as TMetricsProviderContext
);

const MetricsProvider: FC<TMetricsProviderProps> = function ({ metrics, children }) {
  return (
    <MetricsProviderContext.Provider value={metrics}>{children}</MetricsProviderContext.Provider>
  );
};

export { MetricsProvider, MetricsProviderContext };

import type { GuestQuery } from './gql/types';

type GetGuestNameProps = {
  guestInfo?: GuestQuery['guest'] | null;
  ignoreLocalizedName?: boolean;
};

export const getGuestFirstName = ({ guestInfo, ignoreLocalizedName }: GetGuestNameProps) => {
  const guestNameObj = guestInfo?.personalinfo?.name;
  if (ignoreLocalizedName) {
    return guestNameObj?.firstName || '';
  }
  return guestNameObj?.nameLocalizations?.[0]?.firstName || guestNameObj?.firstName || '';
};

export const getGuestLastName = ({ guestInfo, ignoreLocalizedName }: GetGuestNameProps) => {
  const guestNameObj = guestInfo?.personalinfo?.name;
  if (ignoreLocalizedName) {
    return guestNameObj?.lastName || '';
  }
  return guestNameObj?.nameLocalizations?.[0]?.lastName || guestNameObj?.lastName || '';
};

export const getGuestNameFmt = ({ guestInfo, ignoreLocalizedName }: GetGuestNameProps) => {
  const guestNameObj = guestInfo?.personalinfo?.name;
  if (ignoreLocalizedName) {
    return guestNameObj?.nameFmt || '';
  }
  return guestNameObj?.nameLocalizations?.[0]?.nameFmt || guestNameObj?.nameFmt || '';
};

export const getGuestNameObj = (
  props: GetGuestNameProps
):
  | (Omit<
      NonNullable<GuestQuery['guest']>['personalinfo']['name'],
      'nameLocalizations' | 'nameFmt'
    > & {
      nameFmt: string;
    })
  | undefined => {
  return props.guestInfo
    ? {
        nameFmt: getGuestNameFmt(props),
        firstName: getGuestFirstName(props),
        lastName: getGuestLastName(props),
      }
    : undefined;
};

export const defaultPhoneNumbers = {
  number: '8556721138',
  friendlyNumber: '855-672-1138',
};

export const HotelImageVariantSize = {
  Md: 'md',
  Sm: 'sm',
  Xs: 'xs',
} as const;

export const HotelPage = {
  HOME: 'home',
  GALLERY: 'gallery',
  HOTELINFO: 'hotel-info',
  ROOMS: 'rooms',
  DINING: 'dining',
  LOCATION: 'hotel-location',
  MEETINGSEVENTS: 'events',
  SPA: 'spa',
  GOLF: 'golf',
  EXPLORE: 'explore',
  ACTIVITIES: 'activities',
  OFFERS: 'offers',
} as const;

export const HotelGolfFeature = {
  cartRental: 'cartRental',
  instruction: 'instruction',
  practiceFacilities: 'practiceFacilities',
  proShop: 'proShop',
} as const;

export const disableMetricsCookie = 'OHW_PROP_disable_metrics';

export const goUserTypes = ['TMH', 'TPH', 'FF', 'OAS', 'ODM', 'GHFT', 'GHFF'];

export const facebookAppId = '253862148111843';

type IAppConfig = {
  DEFAULT_BRAND_CODE: string;
  DEFAULT_BRAND_NAME: string;
  DISABLE_METRICS_COOKIE: string;
};

export const config: IAppConfig = {
  DEFAULT_BRAND_CODE: 'HH',
  DEFAULT_BRAND_NAME: 'Hilton',
  DISABLE_METRICS_COOKIE: 'OHW_PROP_disable_metrics',
};

export const grayBlurDataUrl =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN88h8AAs0B5QWO2loAAAAASUVORK5CYII=';

export const GLOBAL_NAMESPACES = [
  'accessibility-grid',
  'alerts',
  'common',
  'map',
  'dx-search',
  'events',
  'hotel-policies',
  'dx-utility-rail',
  'osc-alert',
  'osc-breadcrumbs',
  'osc-calendar',
  'osc-carousel',
  'osc-chip',
  'osc-date-picker',
  'osc-dialog',
  'osc-footer',
  'osc-form',
  'osc-header',
  'osc-info-popup',
  'osc-language-selector',
  'osc-link',
  'osc-location',
  'osc-pagination',
  'osc-rooms',
  'osc-shop-form',
  'osc-speedbump',
  'osc-special-rates',
  'osc-tooltip',
  'trip-advisor',
  'osc-join-form-form',
  'osc-join-form-join',
  'osc-join-form-join-button',
];

export const DayOfTheWeek = {
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THR: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
  SUN: 'sunday',
} as const;

export const PROPERTY_DATA_QUERY_KEY = ['propertyData'] as const;

export const EARLY_DEPARTURE_FEE = { FIXED: 'fixed', PERCENTAGE: 'percentage' } as const;

/**
 * Return `DO_NOT_RENDER` from the component data mapper when you do not want a
 * component to render on the page.
 */
export const DO_NOT_RENDER = { DO_NOT_RENDER: true } as const;

/** Environment used for property configuration. */
export const CONFIG_ENV = {
  DEV: 'dev',
  PROD: 'prod',
} as const;

export const PROPERTY_TEMPLATE = {
  INSPIRED: 'inspired',
  TAILORED: 'tailored',
} as const;

export const CUSTOMER_SERVICE_URL = 'https://help.hilton.com/s/';

export const RESERVATION_URL = 'https://www.hilton.com/en/book/reservation/find/';

export const SEARCH_STATE_QUERY_PARAMS = {
  AVAILABLE: [
    'aaaRate',
    'aarpRate',
    'arrivalDate',
    'corporateCode',
    'departureDate',
    'flexibleDates',
    'governmentOrMilitaryRate',
    'groupCode',
    'numRooms',
    'numAdults',
    'numChildren',
    'promotionCode',
    'redeemPts',
    'room1ChildAges',
    'room1NumAdults',
    'room1NumChildren',
    'room2ChildAges',
    'room2NumAdults',
    'room2NumChildren',
    'room3ChildAges',
    'room3NumAdults',
    'room3NumChildren',
    'room4ChildAges',
    'room4NumAdults',
    'room4NumChildren',
    'room5ChildAges',
    'room5NumAdults',
    'room5NumChildren',
    'room6ChildAges',
    'room6NumAdults',
    'room6NumChildren',
    'room7ChildAges',
    'room7NumAdults',
    'room7NumChildren',
    'room8ChildAges',
    'room8NumAdults',
    'room8NumChildren',
    'room9ChildAges',
    'room9NumAdults',
    'room9NumChildren',
    'seniorRate',
    'sessionToken',
    'specialRateTokens',
    'travelAgentRate',
  ],
  DATES: ['arrivalDate', 'departureDate', 'flexibleDates'],
  ROOM_AGES: [
    'room1ChildAges',
    'room2ChildAges',
    'room3ChildAges',
    'room4ChildAges',
    'room5ChildAges',
    'room6ChildAges',
    'room7ChildAges',
    'room8ChildAges',
    'room9ChildAges',
  ],
  ROOM_NUM: [
    'room1NumAdults',
    'room1NumChildren',
    'room2NumAdults',
    'room2NumChildren',
    'room3NumAdults',
    'room3NumChildren',
    'room4NumAdults',
    'room4NumChildren',
    'room5NumAdults',
    'room5NumChildren',
    'room6NumAdults',
    'room6NumChildren',
    'room7NumAdults',
    'room7NumChildren',
    'room8NumAdults',
    'room8NumChildren',
    'room9NumAdults',
    'room9NumChildren',
  ],
  SPECIAL_RATES: [
    'aaaRate',
    'aarpRate',
    'corporateCode',
    'governmentOrMilitaryRate',
    'groupCode',
    'promotionCode',
    'redeemPts',
    'seniorRate',
    'specialRateTokens',
    'travelAgentRate',
  ],
};

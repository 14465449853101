import { metricsConfig } from '@dx-ui/config-metrics';
import { disableMetricsCookie } from '../constants';
import { env } from '@dx-ui/framework-env';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import AdobeTagManager from './AdobeTagManager';
import { digitalData } from './constants';

function isMetricsDisabled() {
  if (!env('TRACKING_SEED_FILE')) {
    return true;
  }
  if (isBrowser) {
    const localStorageItem = window.localStorage.getItem(disableMetricsCookie);
    if (localStorageItem) {
      try {
        return JSON.parse(localStorageItem);
      } catch {
        return false;
      }
    }
  }
  return false;
}

const metricsParams = {
  debug: /local|test/.test(env('APP_ENV')),
  Interface: AdobeTagManager,
  seedFile: isMetricsDisabled() ? '' : env('TRACKING_SEED_FILE'),
  server: !isBrowser,
  digitalData,
};

export const config = metricsConfig(metricsParams);

import type { DigitalData } from '@dx-ui/config-metrics';

export const digitalData: DigitalData = {
  event: [],
  conductrics_traits: [],
  page: {
    attributes: {
      expType: 'New Hilton',
      siteExperience: 'R',
    },
    category: {
      exp: 'Browser',
      siteType: 'B',
    },
    pageInfo: {},
  },
  user: [],
};

export const defaultPageData = {
  brandCode: 'HI',
  brandName: 'Hilton Hotels & Resorts',
  lang: 'en',
  pageName: 'Home',
  primaryCategory: 'Property',
  experience: 'Browser',
  destinationUrl: '',
  expType: 'New Hilton',
  pageType: '',
  pageDetail: [],
};
